<template>
    <div class="wrapper has_nav_bar">
        <van-nav-bar title="修改密码" @click-left="goUserCenter" left-text="返回" left-arrow fixed/>
        <div class="content">
            <div class="main-content">
                <div class="edit-password">
                    <van-form @submit="onSubmit">
                        <van-cell-group>
                            <van-field v-model="form.oldPwd" type="password" name="原密码" label="原密码" placeholder="请输入" :rules="[{ required: true, message: '请填写原密码' }]" required clearable />
                            <van-field v-model="form.newPwd" type="password" name="新密码" label="新密码" placeholder="请输入" :rules="[{ required: true, message: '请填写新密码' },{pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/, message:'密码由字母和数字组成6到16位',trigger: 'blur'},]" required clearable />
                            <van-field v-model="form.confirmPwd" type="password" name="确认新密码" label="确认新密码" placeholder="请输入" :rules="[{ required: true, message: '请填写确认新密码' }]" required clearable />
                        </van-cell-group>
                        <div class="submit_btn"><van-button type="primary" native-type="submit" block>确认</van-button></div>
                    </van-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {encode64} from "../../assets/js/utils";
    import {updateApplicantPwd} from "../../api/client-api";

    export default {
        data() {
            return {
                projectCode: null,
                form:{
                    oldPwd:'',
                    newPwd:'',
                    confirmPwd:'',
                }
            }
        },
        methods: {
            goUserCenter() {
                this.$router.push({name: 'userCenter', query: {projectCode: this.projectCode}});
            },
            // base64加密开始
            encode64(input) {
                var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
                    + "wxyz0123456789+/" + "=";
                var output = "";
                var chr1, chr2, chr3 = "";
                var enc1, enc2, enc3, enc4 = "";
                var i = 0;
                do {
                    chr1 = input.charCodeAt(i++);
                    chr2 = input.charCodeAt(i++);
                    chr3 = input.charCodeAt(i++);
                    enc1 = chr1 >> 2;
                    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                    enc4 = chr3 & 63;
                    if (isNaN(chr2)) {
                        enc3 = enc4 = 64;
                    } else if (isNaN(chr3)) {
                        enc4 = 64;
                    }
                    output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                        + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                    chr1 = chr2 = chr3 = "";
                    enc1 = enc2 = enc3 = enc4 = "";
                } while (i < input.length);

                return output;
            },
            //修改密码
            onSubmit(){
                let oldPwd = encode64(encode64(this.form.oldPwd) + 'oldPwd');
                let newPwd = encode64(encode64(this.form.newPwd) + 'newPwd');
                let confirmPwd = encode64(encode64(this.form.confirmPwd) + 'confirmPwd');
                updateApplicantPwd({oldPwd: oldPwd, newPwd: newPwd, confirmPwd: confirmPwd}).then((res) => {
                    if (res.data.data === 1) {
                        this.$notify({type: 'success', duration: '1000', message: '密码修改成功，请重新登录'});
                        sessionStorage.removeItem('un');
                        sessionStorage.removeItem('Authorization');
                        this.$router.push({name:'login' , query: {projectCode: this.projectCode}},);
                    } else if (res.data.data === 2) {
                        this.$notify({type: 'danger', duration: '1000', message: '新密码不能与原密码相同'});
                    } else if (res.data.data === 3) {
                        this.$notify({type: 'danger', duration: '1000', message: '两次输入的新密码不同'});
                    } else if (res.data.data === 4) {
                        this.$notify({type: 'danger', duration: '1000', message: '登录已失效'});
                    } else if (res.data.data === 5) {
                        this.$notify({type: 'danger', duration: '1000', message: '获取用户信息失败'});
                    } else if (res.data.data === 6) {
                        this.$notify({type: 'danger', duration: '1000', message: '原始密码输入不正确'});
                    } else {
                        this.$notify({type: 'danger', duration: '1000', message: '密码修改失败'});
                    }
                }).catch((err) => {
                    this.$Message.error('密码修改失败');
                })
            }

        },
        mounted() {
            this.projectCode = this.$route.query.projectCode;
        }
    }
</script>

<style scoped>

</style>
